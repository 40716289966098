<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #server>
      <router-link
        :to="{ name: 'acronisDetail', params: { id: item.id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ item.title }}
      </router-link>
    </template>
    <template #id>
      <div class="tariffs-table-item__ip note-color">
        {{ item.id }}
      </div>
    </template>
    <template #createdate>
      <div class="tariffs-table-item__ip note-color">
        {{ $d(item.createdate, 'short') }}
      </div>
    </template>
    <!--    <template #datacentername>-->
    <!--      <div class="tariffs-table-item__ip note-color">-->
    <!--        {{ item.datacentername }}-->
    <!--      </div>-->
    <!--    </template>-->
    <!--    <template #expiredate>-->
    <!--      <div class="tariffs-table-item__ip note-color">-->
    <!--        {{ $d(item.expiredate, 'short') }}-->
    <!--        <plain-button v-if="hasProlongTool" color="primary" @click="singleProlong(item)">-->
    <!--          {{ $t('actions.prolong') }}-->
    <!--        </plain-button>-->
    <!--      </div>-->
    <!--    </template>-->
    <!--    <template #expiredate>-->
    <!--      <div class="tariffs-table-item__ip note-color">-->
    <!--        {{ $d(item.expiredate, 'short') }}-->
    <!--        <plain-button-->
    <!--          v-if="hasProlongTool"-->
    <!--          color="primary"-->
    <!--          @click="cost === 0 ? singleProlongFree(item) : singleProlong(item)"-->
    <!--        >-->
    <!--          {{ $t('actions.prolong') }}-->
    <!--        </plain-button>-->
    <!--      </div>-->
    <!--    </template>-->
    <!--    <template #period>-->
    <!--      <div class="tariffs-table-item__ip note-color">-->
    <!--        {{ $tc('period.month_c', item.period) }}-->
    <!--      </div>-->
    <!--    </template>-->
    <!--    <template #autoprolong>-->
    <!--      <virtual-autoprolong :tariff="item" />-->
    <!--    </template>-->
    <!--    <template #cost>-->
    <!--      <div class="tariffs-table-item__cost note-color">-->
    <!--        {{ $n(item.cost, 'currency', $i18n.locale) }}-->
    <!--      </div>-->
    <!--    </template>-->
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <template #popover>
          <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
          <acronis-context-menu :tariff="item" class="tariff-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import AcronisContextMenu from '../../../components/AcronisContextMenu';
// import VirtualAutoprolong from '../../../components/AcronisAutoprolong';
import VirtualProlongSingle from '../../../components/AcronisProlongSingle.vue';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import popoverFix from '@/mixins/popoverFix';
export default {
  name: 'TariffsTableRow',
  components: { BaseTableRow, MainLabel, AcronisContextMenu },
  //VirtualAutoprolong
  mixins: [prolongSingle, popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moduleMain: 'moduleAcronis',
      //singleProlongComponent: VirtualProlongSingle,
    };
  },
  computed: {
    tools() {
      return this.$store.state.moduleAcronis.tools;
    },
    cost() {
      return this.item.cost;
    },
    hasProlongTool() {
      return (
        this.item.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.item)
      );
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ",
    "actions": {
      "prolong": "Продлить"
    },
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "free": "Активировать",
      "cancel": "Отмена",
      "confirm": "Оплатить",
      "order": "В корзину"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
 &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
