<template>
  <main-card class="tariff-card">
    <template #header>
      <router-link
        :to="{ name: 'acronisDetail', params: { id: tariff.id } }"
        class="tariff-card__title-high big-title"
      >
        {{ tariff.title }}
      </router-link>
    </template>
    <template #headerAction>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="bottom-end"
        :popper-options="popperOptions"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-card__popover"
      >
        <template #popover>
          <plain-button icon="more" class="tariff-card__more" @click="togglePopoverState" />
          <acronis-context-menu :tariff="tariff" class="tariff-card__context-menu"
        /></template>
      </v-popover>
    </template>
    <template #headerEnd>
      <main-label :color="tariff.status.color" class="tariff-card__label">
        {{ $t(`status.${tariff.status.key}`) }}
      </main-label>
    </template>
    <template #bodyEnd>
      <div class="tariff-card__activity">
        <plain-button color="primary" class="tariff-card__prolong" @click="goToPrice()">
          {{ $t('price') }}
        </plain-button>
      </div>
    </template>
    <div class="tariff-card__content">
      <div class="tariff-card__title medium-title">
        {{ $t(`info`) }}
      </div>
      <div class="tariff-card__activity-date">
        <div class="tariff-card__note note-text">
          {{ $t(`created`, { date: createdate }) }}
        </div>
      </div>

      <div class="tariff-card__content">
        <div class="tariff-card__activity">
          <plain-button color="primary" class="tariff-card__prolong" @click="goToWindow()">
            {{ $t('panel') }}
          </plain-button>
          <plain-button color="primary" class="tariff-card__prolong" @click="goToFaq()">
            {{ $t(`faq`) }}
          </plain-button>
        </div>
      </div>
    </div>
    <template #footer>
      <!--      <tariff-spec v-bind="tariff.specs" />-->
    </template>
  </main-card>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
// import TariffSpec from './TariffSpec';
import AcronisContextMenu from '../../../components/AcronisContextMenu.vue';
import AcronisProlongSingle from '../../../components/AcronisProlongSingle.vue';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import popoverFix from '@/mixins/popoverFix';
import { FAQLINK } from '../../../../../../public/config';
export default {
  name: 'TariffCard',
  components: { MainCard, MainLabel, AcronisContextMenu },
  // TariffSpec, AcronisAutoprolong
  mixins: [prolongSingle, popoverFix],
  props: {
    tariff: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popperOptions: {
        modifiers: {
          flip: {
            padding: { top: 60 },
          },
        },
      },
      moduleMain: 'moduleAcronis',
      singleProlongComponent: AcronisProlongSingle,
    };
  },
  computed: {
    isPast() {
      return this.tariff.expiredate < Date.now();
    },
    tools() {
      return this.$store.state.moduleAcronis.tools;
    },
    createdate() {
      return this.$d(this.tariff.createdate, 'short');
    },
    cost() {
      return this.tariff.cost;
    },
    link() {
      return this.tariff.link;
    },
    hasProlongTool() {
      return (
        this.tariff.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.tariff)
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.popperOptions.modifiers.flip.boundary = this.$parent.$el;
    });
    if (this.$parent.$children.findIndex(i => i === this) < 2) {
      this.popperOptions.modifiers.flip.enabled = false;
    }
  },
  methods: {
    goToWindow() {
      this.$store
        .dispatch('moduleVPS/fetchBillMgrToolAction', {
          func: 'gotoserver',
          elid: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            window.open(data.ok.v);
          }
        });
    },
    goToFaq() {
      return window.open(FAQLINK.acronis);
    },
    goToPrice() {
      return window.open(this.link.hint);
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "isActiveUnder": "Действует до {date}",
    "wasActiveUnder": "Действовал до {date}",
    "created": "Создан {date}",
    "panel": "Перейти в панель",
    "price": "Цена",
    "info": "Управление резервными копиями",
    "faq": "Инструкции по настройке",
    "changepricelist": {
      "title": "Смена тарифа",
      "success": "Смена тарифа прошла успешно. Данные о тарифе скоро обновятся."
    },
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "free": "Активировать",
      "cancel": "Отмена",
      "confirm": "Оплатить",
      "order": "В корзину",
      "action": "Продлить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-card {
  min-height: 100%;

   &__title {
    text-decoration: none;
    color: inherit;

    &-high {
      text-decoration: none;
      color: $primary-color;

      &:hover {
        color: darken($primary-color, 20%);
      }
    }
  }
  &__ip {
    margin-top: 2px;
    +breakpoint(sm-and-up) {
      margin-top: 20px;
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      margin-top: 0.25rem;
    }
  }
  &__more {
    display: block;
    margin-right: -0.5rem;

    +breakpoint(sm-and-up) {
      margin-right: -1rem;
      margin-top: -2px;
    }
  }
  &__activity {
    margin-top: 0.5rem;
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }
    &-date {
      margin-top: 2rem;
    }
    &-actions {
      flexy(flex-start, baseline);
      margin-top: 0.75rem;

      +breakpoint(sm-and-up) {
        margin-left: 1.5rem;
      }
    }
  }
  &__autoprolong {
    align-self: center;

    //& + ^[0]__prolong {
    //  margin-left: 1.5rem;
    //}
  }
  &__prolong {
    margin-right: 1.5rem !important;
    //+breakpoint(ms-and-down) {
    //  margin-top: 0.75rem;
    //}

    //+breakpoint(sm-and-up) {
    //  margin-right: 1.5rem;
    //}
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: 260px;
    }
  }
}
</style>
